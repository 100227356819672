<template>
  <div id="home">
    <div class="title">
      <h1>Les nouveaux Engins de déplacement personnel motorisés</h1>
    </div>
    <div class="entrylink">
      <div id="homelink-container" class="mask">
        <div class="homelink link1">
          <router-link
            to="/page/1"
            @mouseover="hover1 = true"
            @mouseleave="hover1 = false"
          >
            <img src="../assets/tmp06.png" alt="" />
            <div class="linkhover" v-show="hover1">
              <p>États<br />des lieux</p>
              <div class="link-background"></div>
            </div>
          </router-link>
        </div>
        <div class="homelink link2">
          <router-link
            to="/page/16"
            @mouseover="hover2 = true"
            @mouseleave="hover2 = false"
          >
            <img src="../assets/tmp02.png" alt="" />
            <div class="linkhover" v-show="hover2">
              <p>Législation</p>
              <div class="link-background"></div>
            </div>
          </router-link>
        </div>
        <div class="homelink link3">
          <router-link
            to="/page/26"
            @mouseover="hover3 = true"
            @mouseleave="hover3 = false"
          >
            <img src="../assets/tmp01.png" alt="" />
            <div class="linkhover" v-show="hover3">
              <p>Conduite<br />à risque</p>
              <div class="link-background"></div>
            </div>
          </router-link>
        </div>
        <div class="homelink link4">
          <router-link
            to="/page/35"
            @mouseover="hover4 = true"
            @mouseleave="hover4 = false"
          >
            <img src="../assets/accueil-intro.png" alt="" />
            <div class="linkhover" v-show="hover4">
              <p>Équipement</p>
              <div class="link-background"></div>
            </div>
          </router-link>
        </div>
      </div>

      <div id="video_link">
        <router-link
          to="/videoplayer"
          @mouseover="hover5 = true"
          @mouseleave="hover5 = false"
          ><img
            class="video-button"
            :class="hover5 ? 'hover' : ''"
            src="../assets/icone-video.png"
            alt=""
        /></router-link>
      </div>
    </div>
    <div class="footer">
      <img src="../assets/gmf-logo.png" alt="">
      <div class="footer-content">
        <p>Prévention du risque routier pour les EDPM</p>
      </div>
    </div>
    <div id="lines">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
        <line x1="40%" y1="0" x2="100%" y2="6%" stroke="#F1E966" />
        <line x1="0" y1="28.4%" x2="100%" y2="19.5%" stroke="#ed6c9b" />
        <line x1="0" y1="69.8%" x2="100%" y2="74.5%" stroke="#7dc07d" />
        <line x1="55%" y1="100%" x2="100%" y2="95%" stroke="#3b4c9c" />
      </svg>
    </div>
  </div>
  <!--<img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  /*components: {
    Videoplayer
  },*/

  data() {
    return {
      hover1: false,
      hover2: false,
      hover3: false,
      hover4: false,
      hover5: false,
    };
  },

  /*methods: {
    mouseOver: function () {
      console.log("over");
      this.over = !this.over;
    },
  },*/
};
</script>

<style lang="scss">
.title {
  background-color: #3f3853;
  color: #5d9ad3;
  position: fixed;
  top: 0;
  height: 50vh;
  width: 100%;
  font-size: 1.6vw;
  padding-top: 6vh;
}

.entrylink {
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 999;
  top: 20vh;
  overflow: hidden;
  height: 70vh;

  .homelink {
    position: absolute;
    height: 30vw;
    margin: 0;
    padding: 0;
    width: 35%;
  }

  .link1,
  .link2,
  .link3,
  .link4 {
    clip-path: polygon(0% 0%, 72% 0%, 80% 120%, 0% 120%);
  }

  .link1 {
    z-index: 4;
    left: 0;

    p {
padding-top: 28%;
padding-left: 15%;
    }
  }

  .link2 {
    z-index: 3;
    left: 25%;

    p {
padding-top: 32%;
padding-left: 15%;
    }

  }
  .link3 {
    z-index: 2;
    left: 50%;

        p {
padding-top: 28%;
padding-left: 15%;
    }
  }

  .link4 {
    z-index: 1;
    left: 75%;

        p {
padding-top: 32%;
padding-left: 10%;
    }
  }

  .linkhover {
    position: absolute;
    top: 0;
    z-index: 8;
    height: 120%;
    width: 100%;

    .link-background {
      background-color: #3f3853;
      opacity: 0.40;
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      top: 0;
    }

    p {
      color: #5d9ad3;
      font-size: 3.4vw;
      font-weight: 700;
      position: absolute;
      z-index: 10;
    }
  }

  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .mask {
    clip-path: polygon(0% 15%, 100% 0%, 100% 90%, 0% 82%);
  }
}

.footer {
  background-color: #5d9ad3;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50vh;

  img {
    position: absolute;
    left: 5vw;
    bottom: 10vh;
}

  .footer-content {
    position: absolute;
    top: 20vh;
    width: 100%;

    p {
      font-size: 2.6vw;
      position: absolute;
      top: 10vh;
      left: 25%;
      z-index: 1;
    }
  }
}

#video_link {
  width: 12vw;
  position: absolute;
  top: 48vh;
  right: 8vw;

  &.video-button {
    width: 10vw;
    position: absolute;
    right: 5vw;
    top: 8vh;

    &.hover {
      width: 10.5vw;
    }
  }
}

div#homelink-container {
  position: relative;
  width: 100%;
  height: 60vh;
}

#lines {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  overflow: hidden;

  svg {
    height: 100%;
    width: 100%;
  }

  line {
    stroke-dasharray: 3%, 2%;
    stroke-width: .8vw;
  }
}
</style>