<template>
  <router-view />
</template>

<style lang="scss">
@font-face {
  font-family: "Museo";
  src: url("/font/Museo-700.woff") format("woff");
}

@font-face {
  font-family: "MyriadPro";
  font-style: normal;
  font-weight: normal;
  src: local("Myriad Pro Regular"), url("/font/Myriadpro-regular.woff") format("woff");
}

@font-face {
  font-family: "MyriadPro";
  font-style: normal;
  font-weight: bold;
  src: local("Myriad Pro Bold"), url("/font/Myriadpro-bold.woff") format("woff");
}

body {
  margin: 0;
}

#app {
  font-family: Museo;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
